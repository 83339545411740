var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "startform_page" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _vm.active_step == 1
          ? _c("div", { staticClass: "startform-step step-one" }, [
              _vm._m(0),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "0px 0 10px 0"
                  }
                },
                [_vm._v("Sandyford mobility survey")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "This publicly funded research will help review mobility and congestion challenges in Sandyford, by filling in this short survey you can help to make the district a better place to live and work!"
                  )
                ]
              ),
              _c("br"),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "Complete the survey to be in with a chance to win a €500 One4All voucher."
                  )
                ]
              ),
              _c("br"),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "Using your work email helps us to identify trends in challenges in particular areas of the district. By continuing you agree to our "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "active",
                      staticStyle: {
                        "text-decoration": "underline",
                        "font-weight": "500"
                      },
                      attrs: { to: "privacy-policy" }
                    },
                    [_vm._v("privacy policy")]
                  ),
                  _vm._v(".")
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "form-item",
                    { attrs: { label: "Full name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Your full name" },
                        model: {
                          value: _vm.info.full_name,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "full_name", $$v)
                          },
                          expression: "info.full_name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "form-item",
                    { attrs: { label: "Email" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "email",
                          placeholder: "Your work email address"
                        },
                        model: {
                          value: _vm.info.email,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "email", $$v)
                          },
                          expression: "info.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.active_step == 2
          ? _c("div", { staticClass: "startform-step step-two" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/testing-icon.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c("br"),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  staticStyle: { "margin-top": "15px" },
                  attrs: { id: "startform_company" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("1. What company do you work for?")
                  ]),
                  _c("el-input", {
                    attrs: { type: "email", placeholder: "Company name" },
                    model: {
                      value: _vm.info.company,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "company", $$v)
                      },
                      expression: "info.company"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "startform-group" },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("2. What is the address of your office?")
                  ]),
                  _c("el-input", {
                    attrs: { type: "email", placeholder: "Office address" },
                    model: {
                      value: _vm.info.company_address,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "company_address", $$v)
                      },
                      expression: "info.company_address"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "startform-group" },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("3. Where are you traveling from?")
                  ]),
                  _c(
                    "el-input",
                    {
                      class: {
                        w100: true,
                        "validate-success": _vm.info.address.validated
                      },
                      staticStyle: { "margin-top": "8px" },
                      attrs: { placeholder: "Only visible by you" },
                      on: { change: _vm.validateAddress },
                      model: {
                        value: _vm.info.address.input_address,
                        callback: function($$v) {
                          _vm.$set(_vm.info.address, "input_address", $$v)
                        },
                        expression: "info.address.input_address"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "append",
                            type: "primary",
                            icon: _vm.info.address.validated
                              ? "el-icon-check"
                              : ""
                          },
                          on: { click: _vm.validateAddress },
                          slot: "append"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.info.address.validated ? "Verified" : "Verify"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_agegroup" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("4. What's your age group?")
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "one-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 22,
                        checked: _vm._q(_vm.info.age, 22)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 22)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "one-option-age-survey" } }, [
                      _vm._v("Less than 26 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "two-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 30,
                        checked: _vm._q(_vm.info.age, 30)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 30)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "two-option-age-survey" } }, [
                      _vm._v("26 to 35 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "three-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 40,
                        checked: _vm._q(_vm.info.age, 40)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 40)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "three-option-age-survey" } }, [
                      _vm._v("36 to 45 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "four-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 50,
                        checked: _vm._q(_vm.info.age, 50)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 50)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "four-option-age-survey" } }, [
                      _vm._v("46 to 55 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "five-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 60,
                        checked: _vm._q(_vm.info.age, 60)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 60)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "five-option-age-survey" } }, [
                      _vm._v("56 to 65 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "six-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 70,
                        checked: _vm._q(_vm.info.age, 70)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 70)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "six-option-age-survey" } }, [
                      _vm._v("More than 65 years old")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_agegroup" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("5. What's your gender?")
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.gender,
                          expression: "info.gender"
                        }
                      ],
                      attrs: {
                        id: "one-option-gender-survey",
                        name: "survey-gender-group",
                        type: "radio",
                        value: "male"
                      },
                      domProps: { checked: _vm._q(_vm.info.gender, "male") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "gender", "male")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "one-option-gender-survey" } },
                      [_vm._v("Male")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.gender,
                          expression: "info.gender"
                        }
                      ],
                      attrs: {
                        id: "two-option-gender-survey",
                        name: "survey-gender-group",
                        type: "radio",
                        value: "female"
                      },
                      domProps: { checked: _vm._q(_vm.info.gender, "female") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "gender", "female")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "two-option-gender-survey" } },
                      [_vm._v("Female")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.gender,
                          expression: "info.gender"
                        }
                      ],
                      attrs: {
                        id: "three-option-gender-survey",
                        name: "survey-gender-group",
                        type: "radio",
                        value: "other"
                      },
                      domProps: { checked: _vm._q(_vm.info.gender, "other") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "gender", "other")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "three-option-gender-survey" } },
                      [_vm._v("Other / I prefer not to say")]
                    )
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm.active_step == 3
          ? _c("div", { staticClass: "startform-step step-three" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/testing-icon.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 0px 0"
                  }
                },
                [_vm._v("Before Covid")]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  staticStyle: { "margin-top": "15px" },
                  attrs: { id: "startform_gotimes" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("6. Your commute to work")
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { sm: 11 }
                        },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving home",
                              "picker-options": {
                                start: "05:30",
                                step: "00:15",
                                end: "15:00"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.go_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "go_leaving_time",
                                  $$v
                                )
                              },
                              expression: "info.commuting_times.go_leaving_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 13 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Journey time"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times.go_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "go_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.go_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_backtimes" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("7. Your commute back home")
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { sm: 11 }
                        },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving office",
                              "picker-options": {
                                start: "12:00",
                                step: "00:15",
                                end: "23:45"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.back_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "back_leaving_time",
                                  $$v
                                )
                              },
                              expression:
                                "info.commuting_times.back_leaving_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 13 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Journey time"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times
                                    .back_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "back_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.back_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_transportmode" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("8. How did you usually commute?")
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { placeholder: "Select one" },
                      model: {
                        value: _vm.info.detault_transport_modes,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "detault_transport_modes", $$v)
                        },
                        expression: "info.detault_transport_modes"
                      }
                    },
                    _vm._l(_vm.options, function(opt) {
                      return _c("el-option", {
                        key: opt.value,
                        attrs: { label: opt.label, value: opt.value }
                      })
                    }),
                    1
                  ),
                  _vm.info.detault_transport_modes == "other"
                    ? _c("el-input", {
                        staticStyle: { "margin-top": "10px !important" },
                        attrs: { placeholder: "(Optional) Please state other" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_options" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "9. What was your main reason for choosing that mode?"
                    )
                  ]),
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "Reason" },
                    model: {
                      value: _vm.info.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "reason", $$v)
                      },
                      expression: "info.reason"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_workfromhome" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("10. Did you work from home before Covid?")
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhome,
                          expression: "info.workfromhome"
                        }
                      ],
                      attrs: {
                        id: "one-option-workfromhome",
                        name: "survey-work-from-home",
                        type: "radio",
                        value: "never"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhome, "never")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "workfromhome", "never")
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "one-option-workfromhome" } }, [
                      _vm._v("Never")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhome,
                          expression: "info.workfromhome"
                        }
                      ],
                      attrs: {
                        id: "two-option-workfromhome",
                        name: "survey-work-from-home",
                        type: "radio",
                        value: "rarely"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhome, "rarely")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "workfromhome", "rarely")
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "two-option-workfromhome" } }, [
                      _vm._v("Rarely (once or twice a month)")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhome,
                          expression: "info.workfromhome"
                        }
                      ],
                      attrs: {
                        id: "three-option-workfromhome",
                        name: "survey-work-from-home",
                        type: "radio",
                        value: "regulary"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhome, "regulary")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "workfromhome", "regulary")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "three-option-workfromhome" } },
                      [_vm._v("Regularly (1 or more days a week)")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhome,
                          expression: "info.workfromhome"
                        }
                      ],
                      attrs: {
                        id: "four-option-workfromhome",
                        name: "survey-work-from-home",
                        type: "radio",
                        value: "always"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhome, "always")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "workfromhome", "always")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "four-option-workfromhome" } },
                      [_vm._v("Always (Never or very rarely go to the office)")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_parking" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("11. Car parking")
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "dedicated-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "dedicated"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "dedicated")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carparking", "dedicated")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "dedicated-option-car-parking" } },
                      [_vm._v("I have a dedicated car parking space")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "easy-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "easy"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "easy")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carparking", "easy")
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "easy-option-car-parking" } }, [
                      _vm._v("I easily find parking")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "difficult-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "difficult"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "difficult")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carparking", "difficult")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "difficult-option-car-parking" } },
                      [_vm._v("I have trouble parking")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "no_carparking-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "no_carparking"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "no_carparking")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "carparking",
                            "no_carparking"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "no_carparking-option-car-parking" } },
                      [_vm._v("I don't drive")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_happiness" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "12. On average, how happy were you with your commute?"
                    )
                  ]),
                  _c("request-happiness-level", {
                    staticStyle: { "margin-top": "10px" },
                    model: {
                      value: _vm.info.happiness_level,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "happiness_level", $$v)
                      },
                      expression: "info.happiness_level"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.active_step == 4
          ? _c("div", { staticClass: "startform-step step-four" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/testing-icon.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 0px 0"
                  }
                },
                [_vm._v("After Covid")]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  staticStyle: { "margin-top": "15px" },
                  attrs: { id: "startform_workfromhomefuture" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "13. Will you want to continue to work from home when restrictions are lifted?"
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuture,
                          expression: "info.workfromhomefuture"
                        }
                      ],
                      attrs: {
                        id: "one-option-workfromhomefuture",
                        name: "survey-work-from-home-future",
                        type: "radio",
                        value: "never"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhomefuture, "never")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuture",
                            "never"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "one-option-workfromhomefuture" } },
                      [_vm._v("Never")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuture,
                          expression: "info.workfromhomefuture"
                        }
                      ],
                      attrs: {
                        id: "two-option-workfromhomefuture",
                        name: "survey-work-from-home-future",
                        type: "radio",
                        value: "rarely"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhomefuture, "rarely")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuture",
                            "rarely"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "two-option-workfromhomefuture" } },
                      [_vm._v("Rarely (once or twice a month)")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuture,
                          expression: "info.workfromhomefuture"
                        }
                      ],
                      attrs: {
                        id: "three-option-workfromhomefuture",
                        name: "survey-work-from-home-future",
                        type: "radio",
                        value: "regulary"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhomefuture, "regulary")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuture",
                            "regulary"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "three-option-workfromhomefuture" } },
                      [_vm._v("Regularly (1 or more days a week)")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuture,
                          expression: "info.workfromhomefuture"
                        }
                      ],
                      attrs: {
                        id: "four-option-workfromhomefuture",
                        name: "survey-work-from-home-future",
                        type: "radio",
                        value: "always"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.workfromhomefuture, "always")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuture",
                            "always"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "four-option-workfromhomefuture" } },
                      [_vm._v("Always (Never or very rarely go to the office)")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_workfromhomefuturereasons" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("14. What are the main reasons?")
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuturereasons,
                          expression: "info.workfromhomefuturereasons"
                        }
                      ],
                      attrs: {
                        id: "one-option-workfromhomefuturereasons",
                        name: "survey-work-from-home-future-reasons",
                        type: "radio",
                        value: "health"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.info.workfromhomefuturereasons,
                          "health"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuturereasons",
                            "health"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: { for: "one-option-workfromhomefuturereasons" }
                      },
                      [_vm._v("Health concerns")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuturereasons,
                          expression: "info.workfromhomefuturereasons"
                        }
                      ],
                      attrs: {
                        id: "two-option-workfromhomefuturereasons",
                        name: "survey-work-from-home-future-reasons",
                        type: "radio",
                        value: "childcare"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.info.workfromhomefuturereasons,
                          "childcare"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuturereasons",
                            "childcare"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: { for: "two-option-workfromhomefuturereasons" }
                      },
                      [_vm._v("Childcare")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuturereasons,
                          expression: "info.workfromhomefuturereasons"
                        }
                      ],
                      attrs: {
                        id: "three-option-workfromhomefuturereasons",
                        name: "survey-work-from-home-future-reasons",
                        type: "radio",
                        value: "transport"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.info.workfromhomefuturereasons,
                          "transport"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuturereasons",
                            "transport"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: { for: "three-option-workfromhomefuturereasons" }
                      },
                      [_vm._v("Transport")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.workfromhomefuturereasons,
                          expression: "info.workfromhomefuturereasons"
                        }
                      ],
                      attrs: {
                        id: "four-option-workfromhomefuturereasons",
                        name: "survey-work-from-home-future-reasons",
                        type: "radio",
                        value: "other"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.info.workfromhomefuturereasons,
                          "other"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "workfromhomefuturereasons",
                            "other"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: { for: "four-option-workfromhomefuturereasons" }
                      },
                      [_vm._v("Other")]
                    )
                  ]),
                  _vm.info.workfromhomefuturereasons == "other"
                    ? _c("el-input", {
                        staticStyle: { "margin-top": "10px !important" },
                        attrs: { placeholder: "(Optional) Please state other" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_circumstancestogobackoffice" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "15. Under what circumstances would you feel safe going back to the office?"
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.circumstancestogobackoffice,
                          expression: "info.circumstancestogobackoffice"
                        }
                      ],
                      attrs: {
                        id: "one-option-circumstancestogobackoffice",
                        name: "survey-circumstancestogobackoffice",
                        type: "checkbox",
                        value: "restrictions_lifted"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.info.circumstancestogobackoffice
                        )
                          ? _vm._i(
                              _vm.info.circumstancestogobackoffice,
                              "restrictions_lifted"
                            ) > -1
                          : _vm.info.circumstancestogobackoffice
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.info.circumstancestogobackoffice,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "restrictions_lifted",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.info,
                              "circumstancestogobackoffice",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: { for: "one-option-circumstancestogobackoffice" }
                      },
                      [_vm._v("As soon as restrictions as lifted")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.circumstancestogobackoffice1,
                          expression: "info.circumstancestogobackoffice1"
                        }
                      ],
                      attrs: {
                        id: "two-option-circumstancestogobackoffice",
                        name: "survey-circumstancestogobackoffice",
                        type: "checkbox",
                        value: "lowtransmission"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.info.circumstancestogobackoffice1
                        )
                          ? _vm._i(
                              _vm.info.circumstancestogobackoffice1,
                              "lowtransmission"
                            ) > -1
                          : _vm.info.circumstancestogobackoffice1
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.info.circumstancestogobackoffice1,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "lowtransmission",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice1",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice1",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.info,
                              "circumstancestogobackoffice1",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: { for: "two-option-circumstancestogobackoffice" }
                      },
                      [_vm._v("When community transmission rate is low")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.circumstancestogobackoffice2,
                          expression: "info.circumstancestogobackoffice2"
                        }
                      ],
                      attrs: {
                        id: "three-option-circumstancestogobackoffice",
                        name: "survey-circumstancestogobackoffice",
                        type: "checkbox",
                        value: "vaccine"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.info.circumstancestogobackoffice2
                        )
                          ? _vm._i(
                              _vm.info.circumstancestogobackoffice2,
                              "vaccine"
                            ) > -1
                          : _vm.info.circumstancestogobackoffice2
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.info.circumstancestogobackoffice2,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "vaccine",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice2",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice2",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.info,
                              "circumstancestogobackoffice2",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "three-option-circumstancestogobackoffice"
                        }
                      },
                      [_vm._v("After I get the vaccine")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.circumstancestogobackoffice3,
                          expression: "info.circumstancestogobackoffice3"
                        }
                      ],
                      attrs: {
                        id: "four-option-circumstancestogobackoffice",
                        name: "survey-circumstancestogobackoffice",
                        type: "checkbox",
                        value: "other"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.info.circumstancestogobackoffice3
                        )
                          ? _vm._i(
                              _vm.info.circumstancestogobackoffice3,
                              "other"
                            ) > -1
                          : _vm.info.circumstancestogobackoffice3
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.info.circumstancestogobackoffice3,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "other",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice3",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info,
                                  "circumstancestogobackoffice3",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.info,
                              "circumstancestogobackoffice3",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "four-option-circumstancestogobackoffice"
                        }
                      },
                      [_vm._v("Other")]
                    )
                  ]),
                  _vm.info.circumstancestogobackoffice3
                    ? _c("el-input", {
                        staticStyle: { "margin-top": "10px !important" },
                        attrs: { placeholder: "(Optional) Please state other" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_circumstancestogobackoffice" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "16. Which alternative transport modes are you open to when your company will return to the office?"
                    )
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { placeholder: "Select one" },
                      model: {
                        value: _vm.info.gobackofficeoptions,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "gobackofficeoptions", $$v)
                        },
                        expression: "info.gobackofficeoptions"
                      }
                    },
                    _vm._l(_vm.options, function(opt) {
                      return _c("el-option", {
                        key: opt.value,
                        attrs: { label: opt.label, value: opt.value }
                      })
                    }),
                    1
                  ),
                  _vm.info.gobackofficeoptions == "other"
                    ? _c("el-input", {
                        staticStyle: { "margin-top": "10px !important" },
                        attrs: { placeholder: "(Optional) Please state other" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_encouraged" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "17. Would you be encouraged to have an active commute (walking, running or cycling) if the following was provided?"
                    )
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { multiple: "", placeholder: "Select one" },
                      model: {
                        value: _vm.info.encouraged,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "encouraged", $$v)
                        },
                        expression: "info.encouraged"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label:
                            "Shower areas (provided / increased / improved)",
                          value:
                            "Shower areas (provided / increased / improved)"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Lockers Provided",
                          value: "Lockers Provided"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Drying room for gear provided",
                          value: "Drying room for gear provided"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Cycle parking increased",
                          value: "Cycle parking increased"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Additional cycle lanes",
                          value: "Additional cycle lanes"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Additional footpath space",
                          value: "Additional footpath space"
                        }
                      }),
                      _c("el-option", {
                        attrs: { label: "Other", value: "Other" }
                      })
                    ],
                    1
                  ),
                  _vm.info.encouraged == "Other" ||
                  (_vm.info.encouraged || []).includes("Other")
                    ? _c("el-input", {
                        staticStyle: { "margin-top": "10px !important" },
                        attrs: {
                          placeholder: "(Optional) Please state your opinion"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_apppostcovid" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "18. We want to improve Mobility post-Covid, not simply go back to the old normal. Will you help us by installing a new Mobility app that will measure your trips CO2 and offer you rewards for active and sustainable travel? (All collected data will be aggregated and anonymous)."
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.apppostcovid,
                          expression: "info.apppostcovid"
                        }
                      ],
                      attrs: {
                        id: "one-option-apppostcovid",
                        name: "survey-apppostcovid",
                        type: "radio",
                        value: "yes"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.apppostcovid, "yes")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "apppostcovid", "yes")
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "one-option-apppostcovid" } }, [
                      _vm._v("Yes, I am willing to help and try reduce my CO2")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.apppostcovid,
                          expression: "info.apppostcovid"
                        }
                      ],
                      attrs: {
                        id: "two-option-apppostcovid",
                        name: "survey-apppostcovid",
                        type: "radio",
                        value: "maybe"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.apppostcovid, "maybe")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "apppostcovid", "maybe")
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "two-option-apppostcovid" } }, [
                      _vm._v("I'd like to help but would like to know more")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.apppostcovid,
                          expression: "info.apppostcovid"
                        }
                      ],
                      attrs: {
                        id: "three-option-apppostcovid",
                        name: "survey-apppostcovid",
                        type: "radio",
                        value: "no"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.apppostcovid, "no")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "apppostcovid", "no")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "three-option-apppostcovid" } },
                      [_vm._v("No, I am not interested")]
                    )
                  ]),
                  _vm.info.apppostcovid == "maybe"
                    ? _c("el-input", {
                        staticStyle: { "margin-top": "10px !important" },
                        attrs: {
                          placeholder:
                            "(Optional) Please state your questions/opinion"
                        }
                      })
                    : _vm._e(),
                  _vm.info.apppostcovid == "no"
                    ? _c("el-input", {
                        staticStyle: { "margin-top": "10px !important" },
                        attrs: {
                          placeholder: "(Optional) Please state your reasons"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.active_step == 5
          ? _c("div", { staticClass: "startform-step step-five" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/email-check.png",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [_vm._v("Thanks, you're done!")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "We will notify you of the result of the giveaway to "
                  ),
                  _vm.info.email
                    ? _c("b", [_vm._v(_vm._s(_vm.info.email))])
                    : _vm._e(),
                  !_vm.info.email
                    ? _c("span", [_vm._v("your email")])
                    : _vm._e(),
                  _vm._v(".")
                ]
              ),
              _c("br")
            ])
          : _vm._e()
      ]),
      _vm.button_text[_vm.active_step - 1]
        ? _c("div", { staticClass: "form-navigation-buttons" }, [
            _c(
              "div",
              { staticClass: "navigation-button" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "secondary-color",
                    staticStyle: { "font-size": "17px" },
                    attrs: {
                      disabled: _vm.loading,
                      type: "primary",
                      round: ""
                    },
                    on: { click: _vm.onSubmitForm }
                  },
                  [
                    _vm._v(_vm._s(_vm.button_text[_vm.active_step - 1]) + "  "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right el-icon-arrow-right"
                    })
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { width: "100%", margin: "auto", "text-align": "center" }
      },
      [
        _c("img", {
          staticStyle: { "max-width": "300px" },
          attrs: {
            src:
              "https://visualartists.ie/wp-content/uploads/2018/10/DLR-logo.jpg",
            alt: ""
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }